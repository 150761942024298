import { useOrders } from '~/features/orders';
import { useUserReviews } from '~/features/reviews';

export const useMemberReviews = (reviewId: string) => {
  const { reviews, isLoading } = useUserReviews();
  const { orders } = useOrders();

  const currentReview = computed(() => {
    return reviews.value.find((review) => review.id === reviewId);
  });

  const reviewedOrder = computed(() => {
    if (!currentReview.value?.orderId) {
      return;
    }

    return orders.value?.find(
      (order) => order.id === currentReview.value?.orderId
    );
  });

  const currentReviewedProduct = computed(() => {
    if (!currentReview.value?.productId) {
      return;
    }

    return reviewedOrder.value?.products[currentReview.value?.productId];
  });

  const isReportReview = computed(() => currentReview.value?.survey.isReport);

  return {
    currentReview,
    reviewedOrder,
    currentReviewedProduct,
    isReportReview,
    reviews,
    isLoadingReviews: isLoading,
  };
};
